<template>
   <router-view></router-view>
</template>

<script>
export default {
  name: 'DNSVSMain'
}
</script>

<style scoped>

</style>
